#play-button {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: auto;
}

#play-button-content {
  border-radius: 6px;
  width: 65px;
  height: 35px;
  background-color: #141c1f;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.75;
}

#play-button-content:hover {
  opacity: 0.95;
  cursor: pointer;
}
