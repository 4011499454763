html {
  background-color: #fafafa;
}
body {
  padding: 0px 0px 0px 0px !important;
  margin: 0px 0px 0px 0px !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
  /* overflow: hidden; */
}
body::-webkit-scrollbar {
  display: none;
}
#root {
  padding: 0px 0px 0px 0px !important;
  margin: 0px 0px 0px 0px !important;
  background-color: #fafafa;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
video::-webkit-media-controls-panel {
  background-image: linear-gradient(transparent, transparent) !important;
}
